@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-gray-200
}
.App {
  max-width: 450px;
  @apply p-4 mx-auto
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

select{
  -webkit-appearance: initial;
  -moz-appearance: initial;
  appearance: initial;
  /*padding: 20px 24px;*/
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="%23000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="6 9 12 15 18 9"></polyline></svg>');
  background-repeat: no-repeat;
  background-position: right center;
  background-size:20px;
}

.size-input {
  @apply text-center text-3xl border border-amber-600 shadow-md py-3 px-4 w-full
}
.size-label {
  @apply text-gray-700 font-bold uppercase w-full
}
.size-label-span-container{
  @apply pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3
}
.size-label-span{
  @apply text-gray-500 lowercase text-xl
}
.ov-button {
  @apply text-center text-xl m-1 border border-amber-600 shadow-md py-1 px-8 rounded-md
}
.ov-delete-button {
  @apply flex gap-1 items-center
}
.close-button {
  @apply flex w-full justify-end py-4
}
.icon-button {
@apply flex gap-2 items-center border rounded-md py-2 px-4 bg-white hover:bg-gray-300 text-sm
}
.properties {
  @apply flex flex-col gap-3 mt-3
}
.properties label {
  @apply text-sm text-left text-gray-800 uppercase
}
.properties input, .properties select {
  @apply border rounded-md p-3 bg-white block w-full mt-1 font-extrabold
}
.select-field {
  @apply border rounded-md p-3 bg-white block w-full mt-1 font-extrabold
}

.primary-button {
  @apply mt-8  shadow-md inline-block text-white bg-blue-700 rounded py-2 px-2 font-bold w-full text-center
}
.standard-button {
  @apply mt-8  shadow-md inline-block bg-white text-gray-900 rounded py-2 px-4 font-bold w-full text-center
}
.standard-button-selector {
  @apply mt-4  shadow-md inline-block bg-white text-gray-900 rounded py-2 px-2 font-bold w-full text-center cursor-pointer hover:shadow-2xl
}
.selected-button{
  @apply bg-white shadow-md  border-2 border-blue-700
}

.ov-link-button {
  @apply text-center text-xl m-1 shadow-md py-1 px-8 rounded-md bg-white text-blue-700
}
.Overlay {
  @apply fixed bg-white flex justify-center top-0 left-0 right-0 bottom-0 border border-gray-300
}
.Content{
  @apply w-1/2 justify-center align-middle items-center lg:relative lg:flex
}
.dialog {
  @apply p-5 flex flex-col gap-3 items-center justify-start h-full w-full lg:justify-center lg:border lg:border-gray-300 lg:p-10 lg:rounded-xl lg:shadow-md m-3
}
.size-table {
  @apply text-sm text-gray-800 text-left w-full
}
.size-table td{
  @apply py-1 px-1
}
.size-table tr {
  @apply border-t border-gray-300
}
.num {
  @apply text-right font-semibold
}
.drawing-img {
  @apply h-40
}
.recommendations {
  @apply my-4 bg-white p-4 rounded border border-gray-100
}
.recommendation-table {
  @apply py-1 px-1 text-sm text-gray-800 text-left w-full
}
.recommendation-table tr{
  @apply border-b border-gray-300

}
.recommendation-table td{
  @apply p-1
}
.info-header {
  @apply py-1 bg-gray-400 text-sm font-semibold px-4 text-white
}
.library {
  @apply p-4 mb-4 rounded-md border border-gray-300 bg-white shadow-md cursor-grab
}
.library svg {
  @apply w-full
}
.details {
  @apply flex gap-5 text-start text-gray-700 font-bold uppercase w-full py-3
}
.details-input {
  @apply text-start text-xl font-normal border border-amber-600 shadow-md py-3 px-4 w-full
}
.details-span {
  @apply text-xl font-normal normal-case
}
.details-logo {
  @apply w-32 h-32
}
.my-projects-container {
  @apply container flex flex-col items-center bg-white rounded-t mt-5
}
.my-projects-details {
  @apply flex gap-2
}
.my-projects-delete-button {
  @apply bg-white flex justify-end rounded-b p-2
}
.image-container {
  @apply w-44 h-52
}
.radio-label span{
  @apply text-xs font-thin
}
.radio-label {
  @apply block h-24 bg-white border text-sm font-semibold border-gray-300 rounded-md cursor-pointer select-none p-2 peer-checked:bg-blue-700 peer-checked:text-white
}

.voltage-warning{
  @apply outline-red-600 border-8 border-red-600 focus:border-red-600
}
.voltage-warning-yellow{
  @apply outline-yellow-400 border-8 border-yellow-400 focus:border-yellow-400
}
.btn-disable{
  @apply opacity-50 cursor-not-allowed mt-8 shadow-md inline-block text-white bg-blue-700 rounded py-2 px-2 font-bold w-full text-center
}
.under-floor-label {
  @apply mt-2 block h-8 bg-white border text-sm border-gray-300 rounded-md cursor-pointer select-none p-2 peer-checked:bg-blue-700 peer-checked:text-white h-9
}
